import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/stepper/accessibility",
  "title": "Stepper - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`It is advisable to use colour combinations provided by the implementation.`}</strong>{` These combinations are ensured to comply with WCAG AA requirements. When customising colours, refer to `}<InternalLink href="/foundation/design-tokens/colour" mdxType="InternalLink">{`colour guidelines`}</InternalLink>{` to ensure accessibility.`}</li>
      <li parentName="ul">{`Note that the step label is a mandatory property. This label will be set as the `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` of the step.`}
        <ul parentName="li">
          <li parentName="ul">{`This label will also be in the step heading element below the stepper.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Each form step should have a heading element of the correct level. It is recommended to use `}<inlineCode parentName="li">{`stepHeading`}</inlineCode>{` property to render the heading automatically.`}
        <ul parentName="li">
          <li parentName="ul">{`If you render the step heading separately, make sure the focus is moved to that heading when the user is moving between steps. This has been taken care of for you if you use the `}<inlineCode parentName="li">{`stepHeading`}</inlineCode>{` variant.`}</li>
          <li parentName="ul">{`You can control the aria level of the heading with the `}<inlineCode parentName="li">{`stepHeadingAriaLevel`}</inlineCode>{` property.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      